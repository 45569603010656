

















import Vue, { PropType } from "vue";
import Avatar from "@/components/avatar/Avatar.vue";
import { User } from "@/generated/graphql.ts";

export default Vue.extend({
  name: "AvatarGroup",
  components: { Avatar },
  props: {
    users: {
      type: Array as PropType<User[]>,
      default: () => []
    },
    big: {
      type: Boolean,
      default: false
    },
    threshold: {
      type: Number,
      default: 5
    },
    nbUsers: {
      type: Number,
      required: true
    }
  },
  computed: {
    displayedUsers(): User[] {
      return this.users.slice(0, this.threshold);
    },
    nbHiddenUsers(): number {
      return this.nbUsers - this.users.slice(0, this.threshold).length;
    },
    size(): string {
      return this.big ? "50px" : "35px";
    },
    overlap(): number {
      return this.big ? 0.2 : 0.4;
    }
  },
  methods: {
    fullName(value: User) {
      return value ? `${value.firstName} ${value.lastName}` : "";
    }
  }
});
