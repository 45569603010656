























































































































































































































































































































































import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";
import Avatar from "@/components/avatar/Avatar.vue";
import AvatarGroup from "@/components/avatar/AvatarGroup.vue";
import FilterTable from "@/components/table/FilterTable.vue";
import BillingIcon from "@/components/icons/BillingIcon.vue";
import { Option } from "@/components/form/EnumSelect.vue";
import { toOptions } from "@/enum";

import { ActionTypes } from "@/store/action-types";
import {
  DashboardBilling,
  DashboardInformation,
  DashboardUser,
  DashboardWorkspace
} from "@/store/modules/dashboard";
import { Paginated } from "@/types/types";
import { format } from "date-fns";
import mixins from "vue-typed-mixins";
import filterTableMixin from "@/mixins/filterTable";
import { DashboardWorkspaceFilter } from "@/models/workspace";
import { MutationTypes } from "@/store/modules/dashboard";
import { RouteName } from "@/router";
import { OrganizationWorkspace, Status } from "@/generated/graphql";

const defaultFilter: DashboardWorkspaceFilter = {
  name: "",
  status: "ACTIVE"
};

export default mixins(
  filterTableMixin<DashboardWorkspaceFilter>(
    defaultFilter,
    ["price", "status"],
    false
  )
).extend({
  components: {
    LayoutWithPageHeader,
    WorkspaceLogo,
    Avatar,
    AvatarGroup,
    FilterTable,
    BillingIcon
  },
  data() {
    return {
      fields: [
        { key: "workspace", label: "Workspace" },
        { key: "plan", label: "Plan" },
        {
          key: "usage",
          label: "Usage",
          tdClass: "text-right pr-5",
          thClass: "text-right pr-5"
        },
        { key: "renewal", label: "Renewal" }
      ],
      infoRoute: RouteName.INFORMATIONS,
      invoicesRoute: RouteName.INVOICES,
      usersRoute: RouteName.USERS,
      isActiveOpenWorkspace: true
    };
  },
  destroyed() {
    this.$storeTyped.commit(MutationTypes.RESET_DASHBOARD_DATA);
  },
  computed: {
    orgId(): string | null {
      return this.$storeTyped.state.organizationId;
    },
    informations(): DashboardInformation | null {
      return this.$storeTyped.state.dashboard.informations;
    },
    billing(): DashboardBilling | null {
      return this.$storeTyped.state.dashboard.billing;
    },
    users(): DashboardUser[] {
      return this.$storeTyped.state.dashboard.users;
    },
    statusOptions(): Option<string | null>[] {
      return [...toOptions(Status)];
    },
    favoriteWorkspaces(): Paginated<DashboardWorkspace> {
      return this.$storeTyped.state.dashboard.favoriteWorkspaces;
    },
    workspaces(): Paginated<DashboardWorkspace> {
      return this.$storeTyped.state.dashboard.workspaces;
    },
    invoiceDate(): string {
      // eslint-disable-next-line
      return this.billing
        ? format(
            new Date(this.billing.year, this.billing.month - 1),
            "MMM. yyyy"
          )
        : "";
    },
    isBillingCardDisplayed(): boolean {
      return this.billing !== null;
    },
    billingAutoLayout(): object {
      return this.isBillingCardDisplayed
        ? {
            cols: 12,
            lg: 6,
            xl: 4
          }
        : {
            cols: 12,
            lg: 6
          };
    },
    favWsAutoLayout(): object {
      const favWsNb = this.favoriteWorkspaces.list?.length;
      if (favWsNb === 2) {
        return {
          cols: 12,
          lg: 6
        };
      } else if (favWsNb === 1) {
        return {
          cols: 12
        };
      } else {
        return {
          cols: 12,
          lg: 6,
          xl: 4
        };
      }
    }
  },
  watch: {
    orgId: {
      handler(val: string | null) {
        if (val !== null) {
          this.$storeTyped.dispatch(ActionTypes.FETCH_DASHBOARD_DATA);
        }
      },
      immediate: true
    }
  },
  created() {
    const el = document.getElementById("onboardScript");
    if (!el) {
      this.createScript();
    }
  },
  methods: {
    createScript() {
      const onBoardingScript = document.createElement("script");
      onBoardingScript.type = "text/javascript";
      onBoardingScript.async = true;
      onBoardingScript.setAttribute("data-customer-id", "stent");
      onBoardingScript.setAttribute(
        "data-project-id",
        "24e14a46-6019-74c8-2ea4-c3db261dd979"
      );
      onBoardingScript.setAttribute("data-cdn-subdomain", "cdn");
      onBoardingScript.setAttribute("id", "onboardScript");
      onBoardingScript.src =
        window.location.protocol +
        "//stent.onboarding.studio/cdn/onboarding.js";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode?.insertBefore(onBoardingScript, s);
    },

    goToWorkspace(id: string) {
      // Get current workspace
      const currentWs = id;
      console.log("====================================");
      console.log(currentWs);
      console.log("====================================");
      const baseUrl = process.env.VUE_APP_WEBAPP;
      const url = "http://app.stent.io/";
      if (baseUrl && baseUrl != "undefined") {
        window.open(`${baseUrl}${currentWs}`, "_blank");
      } else {
        window.open(`${url}${currentWs}`, "_blank");
      }
    },
    gotoEditRoute(id: string, plan: string) {
      if (plan === "Employee Advocacy") {
        localStorage.ea = true;
      } else {
        localStorage.ea = false;
      }
      this.$root.$emit("localstorageEA", localStorage.getItem("ea"));

      this.$router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: this.$route.params.orgId,
          id: id
        }
      });
    },
    gotoExternalWorkspace(ws: OrganizationWorkspace) {
      // Get current workspace
      const currentWs = ws.id;
      console.log("====================================");
      console.log(currentWs);
      console.log("====================================");
      const baseUrl = process.env.VUE_APP_WEBAPP;
      const url = "http://app.stent.io/";
      if (baseUrl && baseUrl != "undefined") {
        window.open(`${baseUrl}${currentWs}`, "_blank");
      } else {
        window.open(`${url}${currentWs}`, "_blank");
      }
    },
    // custom hook from filter mixin
    onFilterChange(filter: DashboardWorkspaceFilter, loadMore = false) {
      console.log("changing filter", filter);
      this.$storeTyped.dispatch(ActionTypes.FETCH_DASHBOARD_WORKSPACES, {
        ...filter,
        loadMore
      });
    },
    loadMoreWorkspaces() {
      this.onFilterChange(this.filter, true);
    },
    loadMorefavoriteWorkspaces() {
      this.$storeTyped.dispatch(ActionTypes.LOAD_MORE_FAVOURITE_WORKSPACES);
    },
    goToWorkspaceAccount(ws: OrganizationWorkspace) {
      if (ws.subscription?.plan.name == "Employee Advocacy") {
        localStorage.ea = true;
      } else {
        localStorage.ea = false;
      }
      this.$root.$emit("localstorageEA", localStorage.getItem("ea"));

      this.$router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: this.$route.params.orgId,
          id: ws.id
        }
      });
    }
  }
});
